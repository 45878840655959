'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Cursor = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _util = require('./util');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Cursor = function () {
  function Cursor(buffer) {
    _classCallCheck(this, Cursor);

    if (!(buffer instanceof Buffer)) {
      buffer = typeof buffer === 'number' ? Buffer.alloc(buffer) : Buffer.from(buffer);
    }

    this._setBuffer(buffer);
    this.rewind();
  }

  _createClass(Cursor, [{
    key: '_setBuffer',
    value: function _setBuffer(buffer) {
      this._buffer = buffer;
      this.length = buffer.length;
    }
  }, {
    key: 'buffer',
    value: function buffer() {
      return this._buffer;
    }
  }, {
    key: 'tap',
    value: function tap(cb) {
      cb(this);
      return this;
    }
  }, {
    key: 'clone',
    value: function clone(newIndex) {
      var c = new this.constructor(this.buffer());
      c.seek(arguments.length === 0 ? this.tell() : newIndex);

      return c;
    }
  }, {
    key: 'tell',
    value: function tell() {
      return this._index;
    }
  }, {
    key: 'seek',
    value: function seek(op, index) {
      if (arguments.length === 1) {
        index = op;
        op = '=';
      }

      if (op === '+') {
        this._index += index;
      } else if (op === '-') {
        this._index -= index;
      } else {
        this._index = index;
      }

      return this;
    }
  }, {
    key: 'rewind',
    value: function rewind() {
      return this.seek(0);
    }
  }, {
    key: 'eof',
    value: function eof() {
      return this.tell() === this.buffer().length;
    }
  }, {
    key: 'write',
    value: function write(string, length, encoding) {
      return this.seek('+', this.buffer().write(string, this.tell(), length, encoding));
    }
  }, {
    key: 'fill',
    value: function fill(value, length) {
      if (arguments.length === 1) {
        length = this.buffer().length - this.tell();
      }

      this.buffer().fill(value, this.tell(), this.tell() + length);
      this.seek('+', length);

      return this;
    }
  }, {
    key: 'slice',
    value: function slice(length) {
      if (arguments.length === 0) {
        length = this.length - this.tell();
      }

      var c = new this.constructor(this.buffer().slice(this.tell(), this.tell() + length));
      this.seek('+', length);

      return c;
    }
  }, {
    key: 'copyFrom',
    value: function copyFrom(source) {
      var buf = source instanceof Buffer ? source : source.buffer();
      buf.copy(this.buffer(), this.tell(), 0, buf.length);
      this.seek('+', buf.length);

      return this;
    }
  }, {
    key: 'concat',
    value: function concat(list) {
      list.forEach(function (item, i) {
        if (item instanceof Cursor) {
          list[i] = item.buffer();
        }
      });

      list.unshift(this.buffer());

      var b = Buffer.concat(list);
      this._setBuffer(b);

      return this;
    }
  }, {
    key: 'toString',
    value: function toString(encoding, length) {
      if (arguments.length === 0) {
        encoding = 'utf8';
        length = this.buffer().length - this.tell();
      } else if (arguments.length === 1) {
        length = this.buffer().length - this.tell();
      }

      var val = this.buffer().toString(encoding, this.tell(), this.tell() + length);
      this.seek('+', length);

      return val;
    }
  }, {
    key: 'writeBufferPadded',
    value: function writeBufferPadded(buffer) {
      var padding = (0, _util.calculatePadding)(buffer.length);
      var paddingBuffer = Buffer.alloc(padding);

      return this.copyFrom(new Cursor(buffer)).copyFrom(new Cursor(paddingBuffer));
    }
  }]);

  return Cursor;
}();

[[1, ['readInt8', 'readUInt8']], [2, ['readInt16BE', 'readInt16LE', 'readUInt16BE', 'readUInt16LE']], [4, ['readInt32BE', 'readInt32LE', 'readUInt32BE', 'readUInt32LE', 'readFloatBE', 'readFloatLE']], [8, ['readDoubleBE', 'readDoubleLE']]].forEach(function (arr) {
  arr[1].forEach(function (method) {
    Cursor.prototype[method] = function read() {
      var val = this.buffer()[method](this.tell());
      this.seek('+', arr[0]);

      return val;
    };
  });
});

[[1, ['writeInt8', 'writeUInt8']], [2, ['writeInt16BE', 'writeInt16LE', 'writeUInt16BE', 'writeUInt16LE']], [4, ['writeInt32BE', 'writeInt32LE', 'writeUInt32BE', 'writeUInt32LE', 'writeFloatBE', 'writeFloatLE']], [8, ['writeDoubleBE', 'writeDoubleLE']]].forEach(function (arr) {
  arr[1].forEach(function (method) {
    Cursor.prototype[method] = function write(val) {
      this.buffer()[method](val, this.tell());
      this.seek('+', arr[0]);

      return this;
    };
  });
});

exports.Cursor = Cursor;