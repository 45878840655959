"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.select = exports.filter = void 0;
var css_what_1 = require("css-what");
var css_select_1 = require("css-select");
var DomUtils = __importStar(require("domutils"));
var helpers_1 = require("./helpers");
var positionals_1 = require("./positionals");
/** Used to indicate a scope should be filtered. Might be ignored when filtering. */
var SCOPE_PSEUDO = {
    type: "pseudo",
    name: "scope",
    data: null,
};
/** Used for actually filtering for scope. */
var CUSTOM_SCOPE_PSEUDO = __assign({}, SCOPE_PSEUDO);
var UNIVERSAL_SELECTOR = { type: "universal", namespace: null };
function filterByPosition(filter, elems, data, options) {
    var num = typeof data === "string" ? parseInt(data, 10) : NaN;
    switch (filter) {
        case "first":
        case "lt":
            // Already done in `getLimit`
            return elems;
        case "last":
            return elems.length > 0 ? [elems[elems.length - 1]] : elems;
        case "nth":
        case "eq":
            return isFinite(num) && Math.abs(num) < elems.length
                ? [num < 0 ? elems[elems.length + num] : elems[num]]
                : [];
        case "gt":
            return isFinite(num) ? elems.slice(num + 1) : [];
        case "even":
            return elems.filter(function (_, i) { return i % 2 === 0; });
        case "odd":
            return elems.filter(function (_, i) { return i % 2 === 1; });
        case "not": {
            var filtered_1 = new Set(filterParsed(data, elems, options));
            return elems.filter(function (e) { return !filtered_1.has(e); });
        }
    }
}
function filter(selector, elements, options) {
    if (options === void 0) { options = {}; }
    return DomUtils.uniqueSort(filterParsed(css_what_1.parse(selector, options), elements, options));
}
exports.filter = filter;
/**
 * Filter a set of elements by a selector.
 *
 * If there are multiple selectors, this can
 * return elements multiple times; use `uniqueSort`
 * to eliminate duplicates afterwards.
 *
 * @param selector Selector to filter by.
 * @param elements Elements to filter.
 * @param options Options for selector.
 */
function filterParsed(selector, elements, options) {
    if (elements.length === 0)
        return [];
    var _a = helpers_1.groupSelectors(selector), plainSelectors = _a[0], filteredSelectors = _a[1];
    var results = [];
    if (plainSelectors.length) {
        results.push(filterElements(elements, plainSelectors, options));
    }
    for (var _i = 0, filteredSelectors_1 = filteredSelectors; _i < filteredSelectors_1.length; _i++) {
        var filteredSelector = filteredSelectors_1[_i];
        if (filteredSelector.some(css_what_1.isTraversal)) {
            /*
             * Get one root node, run selector with the scope
             * set to all of our nodes.
             */
            var root = helpers_1.getDocumentRoot(elements[0]);
            var sel = __spreadArrays(filteredSelector, [CUSTOM_SCOPE_PSEUDO]);
            results.push(findFilterElements(root, sel, options, true, elements));
        }
        else {
            // Performance optimization: If we don't have to traverse, just filter set.
            results.push(findFilterElements(elements, filteredSelector, options, false));
        }
    }
    if (results.length === 1) {
        return results[0];
    }
    return results.reduce(function (arr, rest) { return __spreadArrays(arr, rest); }, []);
}
function select(selector, root, options) {
    if (options === void 0) { options = {}; }
    var _a = helpers_1.groupSelectors(css_what_1.parse(selector, options)), plain = _a[0], filtered = _a[1];
    var results = filtered.map(function (sel) {
        return findFilterElements(root, sel, options, true);
    });
    // Plain selectors can be queried in a single go
    if (plain.length) {
        results.push(findElements(root, plain, options, Infinity));
    }
    // If there was only a single selector, just return the result
    if (results.length === 1) {
        return results[0];
    }
    // Sort results, filtering for duplicates
    return DomUtils.uniqueSort(results.reduce(function (a, b) { return __spreadArrays(a, b); }));
}
exports.select = select;
// Traversals that are treated differently in css-select.
var specialTraversal = new Set(["descendant", "adjacent"]);
function includesScopePseudo(t) {
    return (t !== SCOPE_PSEUDO &&
        t.type === "pseudo" &&
        (t.name === "scope" ||
            (Array.isArray(t.data) &&
                t.data.some(function (data) { return data.some(includesScopePseudo); }))));
}
function addContextIfScope(selector, options, scopeContext) {
    return scopeContext && selector.some(includesScopePseudo)
        ? __assign(__assign({}, options), { context: scopeContext }) : options;
}
/**
 *
 * @param root Element(s) to search from.
 * @param selector Selector to look for.
 * @param options Options for querying.
 * @param queryForSelector Query multiple levels deep for the initial selector, even if it doesn't contain a traversal.
 * @param scopeContext Optional context for a :scope.
 */
function findFilterElements(root, selector, options, queryForSelector, scopeContext) {
    var filterIndex = selector.findIndex(positionals_1.isFilter);
    var sub = selector.slice(0, filterIndex);
    var filter = selector[filterIndex];
    /*
     * Set the number of elements to retrieve.
     * Eg. for :first, we only have to get a single element.
     */
    var limit = positionals_1.getLimit(filter.name, filter.data);
    if (limit === 0)
        return [];
    var subOpts = addContextIfScope(sub, options, scopeContext);
    /*
     * Skip `findElements` call if our selector starts with a positional
     * pseudo.
     */
    var elemsNoLimit = sub.length === 0 && !Array.isArray(root)
        ? DomUtils.getChildren(root).filter(DomUtils.isTag)
        : sub.length === 0 || (sub.length === 1 && sub[0] === SCOPE_PSEUDO)
            ? Array.isArray(root)
                ? root
                : [root]
            : queryForSelector || sub.some(css_what_1.isTraversal)
                ? findElements(root, [sub], subOpts, limit)
                : // We know that this cannot be reached with root not being an array.
                    filterElements(root, [sub], subOpts);
    var elems = elemsNoLimit.slice(0, limit);
    var result = filterByPosition(filter.name, elems, filter.data, options);
    if (result.length === 0 || selector.length === filterIndex + 1) {
        return result;
    }
    var remainingSelector = selector.slice(filterIndex + 1);
    var remainingHasTraversal = remainingSelector.some(css_what_1.isTraversal);
    var remainingOpts = addContextIfScope(remainingSelector, options, scopeContext);
    if (remainingHasTraversal) {
        /*
         * Some types of traversals have special logic when they start a selector
         * in css-select. If this is the case, add a universal selector in front of
         * the selector to avoid this behavior.
         */
        if (specialTraversal.has(remainingSelector[0].type)) {
            remainingSelector.unshift(UNIVERSAL_SELECTOR);
        }
        /*
         * Add a scope token in front of the remaining selector,
         * to make sure traversals don't match elements that aren't a
         * part of the considered tree.
         */
        remainingSelector.unshift(SCOPE_PSEUDO);
    }
    /*
     * If we have another filter, recursively call `findFilterElements`,
     * with the `recursive` flag disabled. We only have to look for more
     * elements when we see a traversal.
     *
     * Otherwise,
     */
    return remainingSelector.some(positionals_1.isFilter)
        ? findFilterElements(result, remainingSelector, options, false, scopeContext)
        : remainingHasTraversal
            ? // Query existing elements to resolve traversal.
                findElements(result, [remainingSelector], remainingOpts, Infinity)
            : // If we don't have any more traversals, simply filter elements.
                filterElements(result, [remainingSelector], remainingOpts);
}
function findElements(root, sel, options, limit) {
    if (limit === 0)
        return [];
    // @ts-expect-error TS seems to mess up the type here ¯\_(ツ)_/¯
    var query = css_select_1._compileToken(sel, options, root);
    var elems = css_select_1.prepareContext(root, DomUtils, query.shouldTestNextSiblings);
    return DomUtils.find(function (node) { return DomUtils.isTag(node) && query(node); }, elems, true, limit);
}
function filterElements(elements, sel, options) {
    // @ts-expect-error TS seems to mess up the type here ¯\_(ツ)_/¯
    var query = css_select_1._compileToken(sel, options);
    return elements.filter(query);
}
