'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _int = require('./int');

Object.keys(_int).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _int[key];
    }
  });
});

var _hyper = require('./hyper');

Object.keys(_hyper).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _hyper[key];
    }
  });
});

var _unsignedInt = require('./unsigned-int');

Object.keys(_unsignedInt).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _unsignedInt[key];
    }
  });
});

var _unsignedHyper = require('./unsigned-hyper');

Object.keys(_unsignedHyper).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _unsignedHyper[key];
    }
  });
});

var _float = require('./float');

Object.keys(_float).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _float[key];
    }
  });
});

var _double = require('./double');

Object.keys(_double).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _double[key];
    }
  });
});

var _quadruple = require('./quadruple');

Object.keys(_quadruple).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _quadruple[key];
    }
  });
});

var _bool = require('./bool');

Object.keys(_bool).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _bool[key];
    }
  });
});

var _string = require('./string');

Object.keys(_string).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _string[key];
    }
  });
});

var _opaque = require('./opaque');

Object.keys(_opaque).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _opaque[key];
    }
  });
});

var _varOpaque = require('./var-opaque');

Object.keys(_varOpaque).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _varOpaque[key];
    }
  });
});

var _array = require('./array');

Object.keys(_array).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _array[key];
    }
  });
});

var _varArray = require('./var-array');

Object.keys(_varArray).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _varArray[key];
    }
  });
});

var _option = require('./option');

Object.keys(_option).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _option[key];
    }
  });
});

var _void = require('./void');

Object.keys(_void).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _void[key];
    }
  });
});

var _enum = require('./enum');

Object.keys(_enum).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _enum[key];
    }
  });
});

var _struct = require('./struct');

Object.keys(_struct).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _struct[key];
    }
  });
});

var _union = require('./union');

Object.keys(_union).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _union[key];
    }
  });
});